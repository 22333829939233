<template>
  <div class="row">
    <selection :reference-links="searchLinks" :thesaurusType="thesaurusType" class="col-lg-4"></selection>
    <div class="col-lg-8">
      <button v-if="isAdmin" class="btn btn-primary" @click="addNewConcept()" :disabled="!loadComplete">Nieuw Concept +</button>
      <template v-if="showConcept">
        <i v-if="!loadComplete" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
        <template v-else>
          <h1 class="text-left">
            {{concept.id}} - {{preferredTerm}}
          </h1>
          <locked-warning :concept="concept" />
          <future-changes-warning :concept="concept" />
          <procedure-combination-warning v-for="warning in concept.procedureCombinationWarnings" :key="warning.id" :warning="warning" />
          <ul class="nav nav-tabs">
            <router-link tag="li" active-class="active" :to="{name: 'Concept', params: {'id': concept.id}}"><a>Concept</a></router-link>
            <router-link tag="li" active-class="active" :to="{name: 'Relations', params: {'id': concept.id}}"><a>Relaties</a></router-link>
            <router-link tag="li" active-class="active" :to="{name: 'Terms', params: {'id': concept.id}}"><a>Termen</a></router-link>
            <router-link tag="li" active-class="active" :to="{name: 'SnomedPanel', params: {'id': concept.id}}"><a>Snomed</a></router-link>
            <router-link tag="li" active-class="active" :to="{name: 'PatientFriendlySnomedPanel', params: {'id': concept.id}}"><a>Patiëntvriendelijke term</a></router-link>
            <router-link tag="li" active-class="active" :to="{name: 'Specialisms', params: {'id': concept.id}}"><a>Specialismen</a></router-link>
            <router-link v-for="link in typeSpecificLinks" :key="link" tag="li" active-class="active" :to="{name: getProperty(link).routerName, params: {'id': concept.id}}"><a>{{getProperty(link).name}}</a></router-link>
            <router-link tag="li" active-class="active" :to="{name: 'Issues', params: {'id': concept.id}}"><a>Issues</a></router-link>
            <router-link v-if="concept.hasReview" tag="li" active-class="active" :to="{name: 'ConceptReview', params: {'id': concept.id}}"><a><i class="fa fa-exclamation-triangle"></i> Review</a></router-link>
          </ul>
          <router-view :conceptId="conceptId" :key="$route.fullPath"></router-view>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import common from './mixins/common.js'
import Selection from './search/Selection.vue'
import FutureChangesWarning from './FutureChangesWarning.vue'
import ProcedureCombinationWarning from './ProcedureCombinationWarning.vue'
import LockedWarning from './LockedWarning.vue'

export default {
  name: 'Editor',
  components: {
    Selection,
    FutureChangesWarning,
    ProcedureCombinationWarning,
    LockedWarning
  },
  mixins: [common],
  data () {
    return {
      loadComplete: true
    }
  },
  computed: {
    conceptId () {
      return Number.parseInt(this.$route.params.id)
    },
    showConcept () {
      return this.validConceptId(this.conceptId)
    },
    preferredTerm () {
      const term = this.concept.currentPreferredTerm
      if (term) {
        return term.name
      } else {
        return ''
      }
    },
    concept () {
      return this.$store.state.editor.concept
    }, // check if the changed route refers to the same concept
    differentConcept () {
      return !(this.concept && Number.parseInt(this.concept.id) === this.conceptId)
    },
    typeSpecificLinks () {
      if (this.$route.params.type === 'dt') {
        return ['icd10', 'dbc', 'codemapping']
      } else if (this.$route.params.type === 'vt') {
        return ['za', 'upt', 'concilium', 'cbv', 'codemapping', 'procedureCombinations']
      }
      return []
    },
    searchLinks () {
      return this.typeSpecificLinks.filter(l => l !== 'procedureCombinations')
    },
    thesaurusType () {
      return this.$route.params.type
    }
  },
  methods: {
    loadConcept (id) {
      if (this.validConceptId(id)) {
        this.loadComplete = false
        this.$store.dispatch('retrieveConcept', id)
          .then(data => {
            this.loadComplete = true
          })
          .catch(() => {
            this.$router.push({ params: { id: null } })
          })
      }
    },
    validConceptId (id) {
      return Number.isInteger(id)
    },
    addNewConcept () {
      const thesaurusType = this.thesaurusType.toUpperCase()
      const type = thesaurusType === 'DT' ? 'Diagnose' : 'Medische verrichting'

      const concept = {
        startDate: this.generateStartDate(),
        endDate: this.generateEndDate(),
        thesaurusType: thesaurusType,
        type: type,
        updateDate: this.generateUpdateDate(),
        laterality: 'niet bepaald'
      }

      this.loadComplete = false
      this.$store.dispatch('addConcept', concept)
        .then(() => {
          this.loadComplete = true
          this.$router.push({ name: 'Concept', params: { id: this.concept.id } })
        })
    },
    getProperty (prop) {
      return this.$store.getters.getDerivationProperty(prop)
    }
  },
  created () {
    this.loadConcept(this.conceptId)
  },
  beforeRouteUpdate (to, from, next) {
    if (to.params.id && Number.parseInt(to.params.id) !== Number.parseInt(from.params.id)) {
      this.loadConcept(Number.parseInt(to.params.id))
    }
    next()
  }
}
</script>

<style lang="scss" scoped>
  h1{
    margin-top: 0;
  }

  .nav{
    margin-bottom: 20px;
  }
</style>
