<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row" v-if="isAdmin">
        <fieldset :disabled="publishing">
          <div class="col-lg-4 form-group">
            <label for="name">Naam</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Naam"
              v-model="publication.name"
              :disabled="!isPublicationEnabled"
            />
          </div>
          <div class="col-lg-3 form-group">
            <label for="comment">Opmerking</label>
            <input
              type="text"
              class="form-control"
              id="comment"
              placeholder="Opmerking"
              v-model="publication.comment"
              :disabled="!isPublicationEnabled"
            />
          </div>
          <div class="col-lg-3 form-group">
            <label for="officialRelease">Officiële publicatie </label>
            <tooltip
              text="Door hier op te klikken wordt de lijst van concepten, waarvan de start- of einddatum moet worden gewijzigd, leeggemaakt"
              trigger="hover"
            >
              <i class="fa fa-question-circle" />
            </tooltip>
            <select
              id="officialRelease"
              class="form-control"
              v-model="publication.officialRelease"
              :disabled="!isPublicationEnabled"
            >
              <option v-bind:value="true">Ja</option>
              <option v-bind:value="false">Nee</option>
            </select>
          </div>
          <div class="col-lg-2">
            <button
              class="publish-button btn btn-primary btn-block"
              :disabled="!isPublicationEnabled"
              @click="createPublication"
            >
              Publiceer
            </button>
          </div>
        </fieldset>
        <div v-if="!isPublicationEnabled" class="alert alert-warning clearfix">
          <div class="pull-left">
            <i class="fa fa-warning"></i>
            Er kan geen nieuwe publicatie worden aangemaakt omdat er nog items
            in review zijn
          </div>
        </div>
      </div>
      <div class="row" v-if="progress.message !== ''">
        <p class="text-center">{{ progress.message }}</p>
        <progress-bar
          v-model="progress.progress"
          :active="!publishFinished"
          :type="progressType"
          :label="true"
          :striped="true"
          :labelText="percentageFinished"
        ></progress-bar>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <table class="table table-hover">
            <thead>
              <tr>
                <th class="col-lg-2">Naam</th>
                <th class="col-lg-3">Opmerking</th>
                <th class="col-lg-2">Gemaakt op</th>
                <th class="col-lg-1">Officiële publicatie</th>
                <th
                  class="col-lg-1"
                  v-for="format in formats"
                  v-bind:key="format"
                >
                  {{ format }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="pub in publicationsOrdered" v-bind:key="pub.id">
                <td>{{ pub.name }}</td>
                <td>{{ pub.comment }}</td>
                <td>{{ pub.createdOn | datetime }}</td>
                <td>{{ pub.officialRelease === true ? "Ja" : "Nee" }}</td>
                <td v-for="format in formats" v-bind:key="format">
                  <i
                    v-if="pub.links[format]"
                    @click="downloadFile(pub.links[format])"
                    class="fa fa-download"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination
            v-if="totalPage > 1"
            v-model="currentPage"
            :total-page="totalPage"
            :max-size="maxSize"
          ></pagination>
        </div>
      </div>
      <div class="row">
        <hr />
        <file-upload
          :options="sourceOptions"
          :action="importSource"
          buttonText="Bron bestand uploaden"
          acceptFiles=".csv"
        ></file-upload>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../api/publication.js'
import common from './mixins/common.js'
import { ProgressBar, Pagination, Tooltip } from 'uiv'
import FileUpload from './FileUpload.vue'
import _ from 'lodash'

export default {
  name: 'Publications',
  mixins: [common],
  components: {
    ProgressBar,
    FileUpload,
    Pagination,
    Tooltip
  },
  data () {
    return {
      publications: [],
      formats: {},
      publication: {
        name: '',
        comment: '',
        thesaurusType: '',
        officialRelease: false
      },
      progress: {
        message: '',
        progress: 0
      },
      publishing: false,
      progressType: null,
      processInterval: null,
      currentPage: 1,
      maxSize: 10,
      isPublicationEnabled: true,
      optionsPerType: {
        dt: [
          { name: 'ICD-10', value: 'Icd10' },
          { name: 'DBC', value: 'Dbc' },
          { name: 'Apache', value: 'Apache' },
          { name: 'ORPHA', value: 'Orpha' },
          { name: 'PICE', value: 'Pice' },
          { name: 'PatientFriendlySnomed', value: 'PatientFriendlySnomed' }
        ],
        vt: [
          { name: 'ZA', value: 'Za' },
          { name: 'UPT', value: 'Upt' },
          { name: 'Concilium', value: 'Concilium' },
          { name: 'CBV', value: 'Cbv' },
          { name: 'Apache', value: 'Apache' },
          { name: 'PICE', value: 'Pice' },
          { name: 'PatientFriendlySnomed', value: 'PatientFriendlySnomed' }
        ]
      }
    }
  },
  computed: {
    sourceOptions () {
      return this.optionsPerType[this.thesaurusType]
    },
    publicationsOrdered () {
      const index = this.maxSize * this.currentPage
      return _.orderBy(this.publications, 'createdOn', 'desc').slice(
        index - this.maxSize,
        index
      )
    },
    totalPage () {
      return Math.ceil(this.publications.length / this.maxSize)
    },
    publishFinished () {
      return this.progress.progress === 100
    },
    percentageFinished () {
      return this.progress.progress + '%'
    },
    isAdmin () {
      return this.$store.state.global.user.admin
    }
  },
  methods: {
    createPublication () {
      this.publishing = true
      this.progressType = null

      api.addPublication(this.publication).then(
        (data) => {
          this.publications.push(data)
          this.publishing = false
          this.progress.message = 'Finished'
          this.progress.progress = 100
          this.progressType = 'success'
          clearInterval(this.processInterval)
          this.processInterval = null
        },
        (error) => {
          if (error) {
            this.publishing = false
            clearInterval(this.processInterval)
            this.processInterval = null
          }
        }
      )

      this.processInterval = setInterval(this.pollForProgressUpdate, 2000)
    },
    pollForProgressUpdate () {
      api.getProgress().then((data) => {
        if (this.publishing) {
          this.progress = data
        }
      })
    },
    importSource (file, type) {
      return api.uploadSourcefile(file, type)
    }
  },
  created () {
    api.getPublications(this.thesaurusType).then((data) => {
      this.publications = data
    })

    api.getFormats().then((data) => {
      this.formats = data
    })

    this.publication.thesaurusType = this.thesaurusType
  }
}
</script>

<style lang="scss" scoped>
.publish-button {
  margin-top: 25px;
}
.row {
  margin-top: 10px;
}
.fa-download {
  color: #337ab7;
  cursor: pointer;
  &:hover {
    color: #23527c;
  }
}
</style>
