<template>
  <tr :class="classes" @click="clickTerm">
    <td>
      <i v-if="activity.hasFutureActivity" class="fa fa-clock-o text-info"></i>
      {{this.termName}}
    </td>
    <td>{{term.type}}</td>
    <td>{{term.languageCode}}</td>
    <td>{{term.startDate | date}} <date-warning :is-at-start-date="true" :end-date="term.endDate" :auto-update-dates="term.autoUpdateDates"></date-warning></td>
    <td>{{term.endDate | date}} <date-warning :is-at-start-date="false" :end-date="term.endDate" :auto-update-dates="term.autoUpdateDates"></date-warning></td>
    <td>{{term.updateDate | date}}</td>
  </tr>
</template>

<script lang="ts">
import common from '../mixins/common.js'
import Vue from 'vue'
import DateWarning from '../DateWarning.vue'

export default Vue.extend({
  name: 'SnomedTerm',
  components: {
    DateWarning
  },
  props: {
    term: {
      type: Object,
      required: true
    }
  },
  mixins: [common],
  data () {
    return {
    }
  },
  computed: {
    termName () {
      if (this.term.type === 'FSN' || this.term.type === 'patientvriendelijkesnomed') {
        return this.term.name
      }

      if (this.term.type === 'Snomed') {
        return this.term.snomedId
      }

      if (this.term.patientFriendlySnomedId !== null) {
        return this.term.patientFriendlySnomedId
      } else {
        return ''
      }
    },
    activity () {
      return this.term.activity
    },
    isSelectable () {
      return ['Snomed', 'Patient Friendly Snomed'].includes(this.term.type)
    },
    classes () {
      return {
        inactive: this.activity.isExpired,
        'not-selectable': !this.isSelectable,
        info: this.activity.activeInFuture
      }
    }
  },
  methods: {
    clickTerm () {
      this.$emit('click')
    }
  }
})
</script>

<style lang="scss" scoped>
tr{
  cursor: pointer;
  &.not-selectable:hover{
    cursor: default;
  }
  &.inactive{
    opacity: 0.5;
  }
  .fa{
    margin-right: 5px;
  }
}
</style>
