<template>
  <div v-if="conceptData">
    <div class="row">
      <div class="form-group col-lg-6">
        <linked-with v-if="conceptData.thesaurusType === 'VT'" v-model="conceptData.loincCode" :term.sync="conceptData.loincName" :search="searchLoincName()" name="Loinc Code" :link="loincUrl" @input="conceptData.loincChanged = true; loading = true" @loadingCompleted="conceptData.loincName = $event; loading = false" :disabled="conceptData.loincApproved">
          <approve-button slot="approve" :approved="conceptData.loincApproved" :id="conceptData.id" type="Loinc koppeling" dispatch="approveLoinc" @finished="loadConceptData()" :enable="!isObsoleteConcept"></approve-button>
        </linked-with>

        <label for="type">Type</label>
        <select id="type" class="form-control" v-model="conceptData.type">
          <option v-for="(type, index) in types" :key="index" >
            {{type}}
          </option>
       </select>
      </div>

      <div class="form-group col-lg-6 text-left">
        <dates :disabled="!conceptData.autoUpdateDates" :startDate.sync="conceptData.startDate" :endDate.sync="conceptData.endDate" :updateDate.sync="conceptData.updateDate" :allowAnyStartDate="true" :autoUpdateDates.sync="conceptData.autoUpdateDates"></dates>
        <span class="text-danger" v-if="!startDateIsValid" >De gekozen startdatum ligt achter de startdatum van onderliggende onderdelen. Dit is niet toegestaan.</span>

        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="conceptData.complication"> Complicatie
          </label>
        </div>

        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="conceptData.gradation"> Gradatie
          </label>
        </div>

        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="conceptData.usesImplant"> Gebruikt implantaat
          </label>
        </div>

        <label for="laterality">Lateraliteit</label>
        <select id="laterality" class="form-control" v-model="conceptData.laterality">
          <option v-for="(laterality, index) in lateralities" :key="index">
            {{laterality}}
          </option>
        </select>

      </div>
      <div class="col-lg-12 buttons">
        <button type="button" @click="validateAndSave" class="btn btn-default pull-left" :disabled="loading || !startDateIsValid">Opslaan <i v-if="loading" class="fa fa-cog fa-spin fa-fw"></i></button>
        <close-button v-if="!loading" class="pull-right" @close="deprecateConcept" />
      </div>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <div class="panel" :class="isObsoleteConcept ? 'panel-default' : 'panel-success'">
          <div class="panel-heading">Termen
            <copy-button :text="copyTerms"></copy-button>
          </div>
          <div class="panel-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="col-lg-8">Term</th>
                  <th class="col-lg-4">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(term, index) in terms" :key="index">
                  <td>{{term.name}}</td>
                  <td>{{term.type}}<copy-button :text="term.name"></copy-button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="hasActiveSnomed" class="panel" :class="isObsoleteConcept ? 'panel-default' : 'panel-success'">
          <div class="panel-heading">Snomed
            <copy-button :text="copyTerms"></copy-button>
          </div>
          <div class="panel-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="col-lg-8">Term</th>
                  <th class="col-lg-4">Type</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(term, index) in snomeds" :key="index">
                  <td>{{term.name}}</td>
                  <td>{{term.type}}<copy-button :text="term.name"></copy-button></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="specialisms.length > 0 && isVT" class="panel" :class="isObsoleteConcept ? 'panel-default' : 'panel-success'">
          <div class="panel-heading">Specialismen
            <copy-button :text="copySpecialisms"></copy-button>
          </div>
          <div class="panel-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="col-lg-8">Naam</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="specialism in specialisms" :key="specialism.id">
                  <td>{{getSpecialismGroupName(specialism.id)}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-if="rolesPerSpecialism.length > 0" class="panel" :class="isObsoleteConcept ? 'panel-default' : 'panel-success'">
          <div class="panel-heading">Rollen
            <copy-button :text="copyRoles"></copy-button>
          </div>
          <div class="panel-body">
            <table class="table table-hover" v-for="roleGroup in rolesPerSpecialism" :key="roleGroup.specialismGroupId">
              <thead>
                <tr>
                  <th colspan="10">{{getSpecialismGroupName(roleGroup.specialismGroupId)}} </th>
                </tr>
              </thead>
              <tbody>
                <template>
                  <tr v-for="role in roleGroup.roles" :key="role.id">
                    <td class="col-lg-2">{{roleName(role)}}</td>
                    <td class="col-lg-8">{{role.value}}</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>

        <derivation-overview :type="derivations.icd10" :groups="conceptData[derivations.icd10.conceptProperty]"></derivation-overview>

        <derivation-overview :type="derivations.concilium" :groups="conceptData[derivations.concilium.conceptProperty]"></derivation-overview>
      </div>
      <div class="col-lg-6">
        <derivation-overview :type="derivations.codemapping" :groups="conceptData[derivations.codemapping.conceptProperty]"></derivation-overview>

        <derivation-overview :type="derivations.dbc" :groups="conceptData[derivations.dbc.conceptProperty]"></derivation-overview>

        <derivation-overview :type="derivations.za" :groups="conceptData[derivations.za.conceptProperty]"></derivation-overview>

        <derivation-overview :type="derivations.upt" :groups="conceptData[derivations.upt.conceptProperty]"></derivation-overview>

        <div class="panel" :class="isObsoleteConcept ? 'panel-default' : 'panel-success'" v-if="conceptData.cbvs.length > 0">
          <div class="panel-heading">CBV
            <copy-button :text="copyCbvs"></copy-button>
          </div>
          <div class="panel-body">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th class="col-lg-3">CBV code</th>
                  <th class="col-lg-9">Naam</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cbv in conceptData.cbvs" :key="cbv.cbvId">
                  <td>{{cbv.cbvId}}</td>
                  <td>{{cbv.name}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '../../../api/editor.js'
import common from '../mixins/common.js'
import DerivationOverview from './DerivationOverview.vue'
import ApproveButton from '../ApproveButton.vue'
import CopyButton from './CopyButton.vue'
import LinkedWith from './LinkedWith.vue'
import CloseButton from '../CloseButton.vue'
import moment from 'moment'

export default {
  name: 'Concept',
  data () {
    return {
      conceptData: {},
      loading: false
    }
  },
  mixins: [common],
  components: { DerivationOverview, ApproveButton, CopyButton, LinkedWith, CloseButton },
  computed: {
    derivations () {
      return this.$store.state.global.referenceProperties
    },
    terms () {
      let removeNonActivePreferred = this.conceptData.terms.filter(t => t.type !== 'voorkeursterm' || t.id === this.conceptData.currentPreferredTerm.id)
      if (!this.isObsoleteConcept) {
        removeNonActivePreferred = removeNonActivePreferred.filter(t => !t.activity.isExpired)
      }
      return this.orderByTermType(removeNonActivePreferred)
    },
    snomeds () {
      const snomeds = []
      if (this.hasActiveSnomed) {
        snomeds.push(...this.activeSnomed)
      }

      if (this.hasActivePatientFriendlySnomed) {
        snomeds.push(...this.activePatientFriendlySnomed)
      }
      return snomeds
    },
    snomedPanelIsVisible () {
      return this.hasActiveSnomed || this.hasActivePatientFriendlySnomed
    },
    hasActiveSnomed () {
      return this.conceptData.snomedRelations.some(sr => sr.activity.currentlyActive)
    },
    hasActivePatientFriendlySnomed () {
      return this.conceptData.patientFriendlySnomedRelations.some(pfsr => pfsr.activity.currentlyActive)
    },
    activeSnomed () {
      const activeSnomed = this.conceptData.snomedRelations.filter(sr => sr.activity.currentlyActive).map(sr => { return { name: sr.snomedId, type: 'Snomed Id' } })
      const activeFsn = this.conceptData.terms.filter(t => t.activity.currentlyActive && t.type === 'FSN')

      return [...activeSnomed, ...activeFsn]
    },
    activePatientFriendlySnomed () {
      const activePatientFriendlySnomed = this.conceptData.patientFriendlySnomedRelations.filter(pfsr => pfsr.activity.currentlyActive).map(pfsr => { return { name: pfsr.patientFriendlySnomedId, type: 'Patient Friendly Snomed Id' } })
      const activePvs = this.conceptData.terms.filter(t => t.activity.currentlyActive && t.type === 'patientvriendelijkesnomed')

      return [...activePatientFriendlySnomed, ...activePvs]
    },
    specialisms () {
      return this.conceptData.specialisms.filter(s => this.isSpecialismActive(s))
    },
    rolesPerSpecialism () {
      var roles = this.conceptData.specialisms
        .filter(s => this.isSpecialismActive(s))
        .flatMap(s => s.roles)
        .filter(r => r.typeId !== this.uitgebreidRoleId)
      var roleSpecialismGroupIds = [...new Set(roles.map(r => r.specialismGroupId))]
      return roleSpecialismGroupIds.map(sp => {
        return { specialismGroupId: sp, roles: roles.filter(r => r.specialismGroupId === sp) }
      })
    },
    copyTerms () {
      return this.terms.reduce((terms, t) => {
        if (t.type === 'synoniem') {
          terms.push(t.name)
        }
        return terms
      }, []).join('; ')
    },
    copySpecialisms () {
      return this.conceptData.specialisms.reduce((result, specialism) => {
        if (this.isSpecialismActive(specialism)) {
          result.push(this.getSpecialismGroupCode(specialism.id))
        }
        return result
      }, []).join('; ')
    },
    copyRoles () {
      return this.rolesPerSpecialism.reduce((result, roleGroup) => {
        var value = this.getSpecialismGroupCode(roleGroup.specialismGroupId) + ': ' + roleGroup.roles.map(r => r.typeId).join(', ')
        result.push(value)
        return result
      }, []).join('; ')
    },
    copyCbvs () {
      return this.conceptData.cbvs.reduce((result, cbv) => {
        result.push(cbv.cbvId)
        return result
      }, []).join('; ')
    },
    snomedUrl () {
      return this.$store.getters.getSnomedLink(this.conceptData.snomedId)
    },
    loincUrl () {
      return `https://s.details.loinc.org/LOINC/${this.conceptData.loincCode}.html?sections=Comprehensive`
    },
    lateralities () {
      return ['niet bepaald', 'nooit', 'mogelijk', 'altijd', 'links', 'rechts', 'beiderzijds']
    },
    types () {
      if (this.conceptData.thesaurusType === 'DT') {
        return ['Diagnose', 'Zorgbehoefte', '@DBC', 'Administratieve uitzondering']
      } else if (this.conceptData.thesaurusType === 'VT') {
        return ['Medische verrichting', 'Paramedische verrichting', 'Materiaal', 'Tandheelkundige verrichting', 'Verpleegkundige verrichting', 'Administratieve verrichting', 'Combinatieverrichting']
      }
      return []
    },
    startDateIsValid () {
      let earliestDate = moment(this.conceptData.startDate)

      for (const [key, value] of Object.entries(this.conceptData)) {
        if (Array.isArray(value) && value.length > 0 && key !== 'issues') {
          value.forEach(v => {
            if (v.startDate) {
              const startDate = moment(v.startDate)
              if (startDate.isBefore(earliestDate)) {
                earliestDate = startDate
              }
            }
          })
        }
      }
      return earliestDate.isSameOrAfter(this.conceptData.startDate)
    }
  },
  methods: {
    async save () {
      this.loading = true
      try {
        await this.$store.dispatch('updateConcept', this.conceptData)
        if (this.conceptData.snomedChanged) {
          this.updateFsnTerms()
        }

        if (this.conceptData.loincChanged) {
          this.setLcnTerm()
        }

        this.loadConceptData()
      } finally {
        this.loading = false
      }
    },
    searchSnomedName () {
      return api.getSnomedName
    },
    searchLoincName () {
      return api.getLoincName
    },
    loadConceptData () {
      this.conceptData = JSON.parse(JSON.stringify(this.$store.state.editor.concept))
    },
    isSpecialismActive (specialism) {
      return specialism.roles.filter(r => this.isActive(r.endDate)).length > 0
    },
    async deprecateConcept (dates) {
      try {
        this.conceptData.endDate = dates.endDate
        await this.$store.dispatch('closeConcept', this.conceptData)
      } finally {
        this.loadConceptData()
      }
    },
    async updateFsnTerms () {
      if (this.conceptData.snomedName) {
        await this.$store.dispatch('updateFsnLinkedTerms', this.conceptData.id)
      } else {
        await this.$store.dispatch('removeLinkedTerm', 'fsn')
      }
    },
    async setLcnTerm () {
      if (this.conceptData.loincName) {
        const term = {
          startDate: this.generateStartDate(),
          endDate: this.generateEndDate(),
          updateDate: this.generateUpdateDate(),
          type: 'LCN',
          languageCode: 'en-GB',
          conceptId: this.conceptData.id,
          name: this.conceptData.loincName
        }
        await this.$store.dispatch('addLcnLinkedTerm', term)
      } else {
        await this.$store.dispatch('removeLinkedTerm', 'lcn')
      }
    },
    async validateAndSave () {
      if (this.startDateIsValid) {
        await this.save()
      }
    }
  },
  created () {
    this.loadConceptData()
  }
}
</script>

<style lang="scss" scoped>
  .buttons{
    margin-bottom: 20px;
  }
</style>
